import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/user/user';
import { UserLogged } from './user-logged';
import { UserHabilidadeForm } from '../models/user/userHabilidadeForm';
import { UserCertification } from '../models/user/userCertification';
import { UserLanguage } from '../models/user/userLanguage';
import { UserExperience } from '../models/user/userExperience';
import { UserModel } from '../models/user/userModel';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';
import { Sistema } from '../models/sistem.enum';

declare const FB: any;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private authenticateTimeout: any;

  constructor(
    private apiService: ApiService,
    private userLogged: UserLogged,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  public async login(username: string, password: string) {
    let data = {
      username: username,
      password: password,
    };
    try {
      let response = await this.apiService
        .post(ApiService.URL + 'login', data)
        .toPromise();
      if (!response.code) {
        localStorage.setItem('jwt', response.token);
        let user: User = response.user;
        this.userLogged.user = user;
        localStorage.setItem('user', JSON.stringify(this.userLogged.user));
        this.userLogged.user.currency = await this.apiService
          .get(ApiService.URL + 'user/currency/' + user.id)
          .toPromise();
        localStorage.setItem('user', JSON.stringify(this.userLogged.user));

        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async getUser(id: number) {
    let user: User;
    if (id != this.userLogged.user.id) {
      return <User>(
        await this.apiService
          .get(ApiService.URL + 'user/' + id + '/' + Sistema.HOMELIFE)
          .toPromise()
      );
    } else {
      user = this.userLogged.user;
      user = await this.apiService
        .get(ApiService.URL + 'user/' + user.id + '/' + Sistema.HOMELIFE)
        .toPromise();
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    }
  }

  public async loginGoogle(user: any) {
    let response = await this.apiService
      .post(ApiService.URL + 'login/google', user)
      .toPromise();
    if (!response.code) {
      localStorage.setItem('jwt', response.token);
      let user: User = response.user;
      localStorage.setItem('user', JSON.stringify(user));
      user.currency = await this.apiService
        .get(ApiService.URL + 'user/currency/' + user.id)
        .toPromise();
      localStorage.setItem('user', JSON.stringify(user));
      return true;
    } else {
      return false;
    }
  }

  public async updateUser(user: User) {
    let updated = await this.apiService
      .post(ApiService.URL + 'user', user)
      .toPromise();
    if (updated) {
      localStorage.setItem('user', JSON.stringify(updated));
    }
  }

  public async deleteUserCertification(userCertification: UserCertification) {
    userCertification = await this.apiService
      .get(ApiService.URL + 'user/certification/' + userCertification.id)
      .toPromise();
    return userCertification;
  }

  public async addUserCertification(userCertification: UserCertification) {
    userCertification = await this.apiService
      .post(ApiService.URL + 'user/certification', userCertification)
      .toPromise();
    return userCertification;
  }

  public async deleteUserLanguage(userLanguage: UserLanguage) {
    userLanguage = await this.apiService
      .get(ApiService.URL + 'user/language/' + userLanguage.id)
      .toPromise();
    return userLanguage;
  }

  public async addUserLanguage(userLanguage: UserLanguage) {
    userLanguage = await this.apiService
      .post(ApiService.URL + 'user/language', userLanguage)
      .toPromise();
    return userLanguage;
  }

  public async deleteUserExperience(userExperience: UserExperience) {
    userExperience = await this.apiService
      .get(ApiService.URL + 'user/experience/' + userExperience.id)
      .toPromise();
    return userExperience;
  }

  public async addUserExperience(userExperience: UserExperience) {
    userExperience = await this.apiService
      .post(ApiService.URL + 'user/experience', userExperience)
      .toPromise();
    return userExperience;
  }

  public async updateUserHabilidade(userhabilidadeForm: UserHabilidadeForm) {
    await this.apiService
      .post(ApiService.URL + 'user/habilidade', userhabilidadeForm)
      .toPromise();
  }

  public updateUserViewMode(user: User) {
    this.apiService
      .get(ApiService.URL + 'user/viewMode/' + user.id)
      .toPromise();
  }

  public async updateUserOnline(user: User) {
    await this.apiService
      .get(ApiService.URL + 'user/online/' + user.id + '/' + user.online)
      .toPromise();
  }

  saveLatLong(user: User) {
    this.apiService.post(ApiService.URL + 'user/savelatlong', user).toPromise();
  }

  async sendEmailRecoverPassword(email: string) {
    return this.apiService
      .get(ApiService.URL + 'user/sendRecoverPassword/' + email)
      .toPromise();
  }

  async setNewPassword(email: string, newPassword: string, code: string) {
    return this.apiService
      .post(ApiService.URL + 'user/setNewPassword', {
        email: email,
        password: newPassword,
        code: code,
      })
      .toPromise();
  }

  public logout() {
    localStorage.clear();
    this.userLogged.user = new UserModel();
    document.location.href = '/';
    //this.socialAuthService.signOut();
  }

  loginFb() {
    // login with facebook then authenticate with the API to get a JWT auth token
    this.facebookLogin()
      .pipe(concatMap((accessToken) => this.authFb(accessToken)))
      .subscribe(() => {
        // get return url from query parameters or default to home page
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      });
  }

  public async authFb(accessToken: any) {
    let response = await this.apiService
      .post(ApiService.URL + 'login/fb', accessToken)
      .toPromise();
    if (!response.code) {
      localStorage.setItem('jwt', response.token);
      let user: User = response.user;
      localStorage.setItem('user', JSON.stringify(user));
      user.currency = await this.apiService
        .get(ApiService.URL + 'user/currency/' + user.id)
        .toPromise();
      localStorage.setItem('user', JSON.stringify(user));
      return true;
    } else {
      return false;
    }
  }

  facebookLogin() {
    // login with facebook and return observable with fb access token on success
    return from(new Promise<any>((resolve) => FB.login(resolve))).pipe(
      concatMap(({ authResponse }) => {
        if (!authResponse) return EMPTY;
        return of(authResponse.accessToken);
      })
    );
  }

  logoutFb() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
    this.router.navigate(['/login']);
  }
}
