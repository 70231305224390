import { Component, Renderer2 } from '@angular/core';
import { User } from '../../models/user/user';
import { UserLogged } from '../../services/user-logged';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilsModule } from '../../utils';
import { LoginService } from '../../services/login.service';
import { UserHabilidade } from '../../models/user/userHabilidade';
import { UserHabilidadeForm } from '../../models/user/userHabilidadeForm';
import { Categoria } from '../../models/categoria/categoria';
import { UserCertificationModel } from '../../models/user/userCertificationModel';
import { UserCertification } from '../../models/user/userCertification';
import { UserLanguage } from '../../models/user/userLanguage';
import { UserLanguageModel } from '../../models/user/userLanguageModel';
import {
  getLanguages,
  getLevelLanguages,
  Language,
} from '../../models/user/language';
import { getTimes, UserExperience } from '../../models/user/userExperience';
import { UserExperienceModel } from '../../models/user/userExperienceModel';
import { Rating } from 'src/app/models/user/rating';
import { RatingImages } from 'src/app/models/user/ratingImages';
import { getNiveis, UserNivel } from '../../models/user/userNivel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalHabilidadesComponent } from '../modais/modal-habilidades/modal-habilidades.component';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss', '../../../darkmode.scss'],
})
export class PerfilComponent {
  selectedRating = 0;

  stars = [
    {
      id: 1,
      icon: 'star',
      class: 'star-gray star-hover star',
    },
    {
      id: 2,
      icon: 'star',
      class: 'star-gray star-hover star',
    },
    {
      id: 3,
      icon: 'star',
      class: 'star-gray star-hover star',
    },
    {
      id: 4,
      icon: 'star',
      class: 'star-gray star-hover star',
    },
    {
      id: 5,
      icon: 'star',
      class: 'star-gray star-hover star',
    },
  ];

  public user: User;
  public avaliacoesImagens: RatingImages[] | undefined;
  public clientProfile: boolean = false;
  public myProfile: boolean = true;
  private url = ApiService.URL + 'proposta/';
  private nivel: string = '';
  public nivelOrdem: number = 0;
  public nomeNivel: string = '';
  public imagemNivel: string = '';
  editPreco: boolean = false;
  editHabilidades: boolean = false;
  editDescricao: boolean = false;
  editArea: boolean = false;
  editNome: boolean = false;
  media_avaliacoes: any;
  avaliacoes: Rating[] = [];
  avaliacoes_1: any = 0;
  avaliacoes_2: any = 0;
  avaliacoes_3: any = 0;
  avaliacoes_4: any = 0;
  avaliacoes_5: any = 0;
  avaliacoes_comentarios: any = 0;
  avaliacoes_media: any = 0;
  editIdiomas: boolean = false;
  editCertificacoes: boolean = false;
  newCertification: UserCertification = new UserCertificationModel();
  newLanguage: UserLanguage = new UserLanguageModel();
  newExperience: UserExperience = new UserExperienceModel();
  userNivel: UserNivel | undefined;
  protected readonly alert = alert;
  protected readonly UtilsModule = UtilsModule;
  public languages: Language[] = getLanguages();
  public timesExperience = getTimes();
  public levelLanguages: Language[] = getLevelLanguages();
  editExperiences: boolean = false;

  constructor(
    private userLogged: UserLogged,
    private meta: Meta,
    private title: Title,
    routes: Router,
    renderer: Renderer2,
    private service: ApiService,
    private loginService: LoginService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {
    let valorAvaliacoes = 0;

    let u = localStorage.getItem('userProfile');
    if (u != null) {
      this.user = JSON.parse(u);
      this.myProfile = false;
    } else {
      this.user = userLogged.user;
    }

    this.service
      .get(ApiService.URL + 'user/listar/avaliacoes')
      .subscribe((resp) => {
        this.avaliacoes = resp;

        this.avaliacoes.forEach((element) => {
          valorAvaliacoes += element.rate;
          this.avaliacoesImagens = element.imagensAvaliacao;
        });

        this.avaliacoes_1 = this.avaliacoes.filter(
          (element) => element.rate == 1
        );
        this.avaliacoes_2 = this.avaliacoes.filter(
          (element) => element.rate == 2
        );
        this.avaliacoes_3 = this.avaliacoes.filter(
          (element) => element.rate == 3
        );
        this.avaliacoes_4 = this.avaliacoes.filter(
          (element) => element.rate == 4
        );
        this.avaliacoes_5 = this.avaliacoes.filter(
          (element) => element.rate == 5
        );
        this.avaliacoes_comentarios = this.avaliacoes.filter(
          (element) => element.description.length > 0
        );
        this.avaliacoes_media = this.avaliacoes.filter(
          (element) => element.imagensAvaliacao.length > 0
        );

        if (this.avaliacoes.length > 0 && isFinite(this.avaliacoes.length)) {
          this.media_avaliacoes = valorAvaliacoes / this.user.avaliacoes.length;
          this.media_avaliacoes = this.media_avaliacoes.toFixed(2);
        } else {
          this.media_avaliacoes = 0;
        }
      });

    this.meta.addTag({
      name: 'og:title',
      content: this.user.name + ' | Freelancer especializado | HomeLife',
    });
    this.meta.addTag({ name: 'og:description', content: 'Sobre Mim: ...' });
    this.title.setTitle(
      this.user.name + ' | Freelancer especializado | HomeLife'
    );
    this.getUserNivel();
  }

  async getUserNivel() {
    this.nivel = 'I';
    const niveis = getNiveis();
    this.service
      .get(ApiService.URL + 'user/nivel/' + this.user.id)
      .subscribe((n) => {
        this.nivel = n.nivel;
        this.userNivel = niveis.get(this.nivel);
      });
  }

  verificarNivel() {
    if (this.userNivel && this.userNivel.nome === 'TOBI') {
      return true;
    }
    return false;
  }

  selectStar(value: any) {
    // prevent multiple selection
    this.stars.filter((star) => {
      if (star.id <= value) {
        star.class = 'star-gold star';
      } else {
        star.class = 'star-gray star';
      }
      return star;
    });
    this.selectedRating = value;
  }

  async updateUser() {
    await this.loginService.updateUser(this.user);
  }

  removeCertification(c: UserCertification) {
    this.loginService.deleteUserCertification(c);
    this.user.certifications = this.user.certifications.filter(
      (c1) => c1.id != c.id
    );
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  async saveCertification(c: UserCertification) {
    c.userId = this.user.id;
    let c1 = await this.loginService.addUserCertification(c);
    if (c.id == null) this.user.certifications.push(c1);
    localStorage.setItem('user', JSON.stringify(this.user));
    this.newCertification = new UserCertificationModel();
  }

  async saveLanguage(l: UserLanguage) {
    l.userId = this.user.id;
    let l1 = await this.loginService.addUserLanguage(l);
    if (l.id == null) this.user.languages.push(l1);
    localStorage.setItem('user', JSON.stringify(this.user));
    this.newLanguage = new UserLanguageModel();
  }

  removeLanguage(l: UserLanguage) {
    this.loginService.deleteUserLanguage(l);
    this.user.languages = this.user.languages.filter((l1) => l1.id != l.id);
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  async saveExperience(l: UserExperience) {
    l.userId = this.user.id;
    let l1 = await this.loginService.addUserExperience(l);
    if (l.id == null) this.user.experiences.push(l1);
    localStorage.setItem('user', JSON.stringify(this.user));
    this.newExperience = new UserExperienceModel();
  }

  removeExperience(l: UserExperience) {
    this.loginService.deleteUserExperience(l);
    this.user.experiences = this.user.experiences.filter((l1) => l1.id != l.id);
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  timeOfExperience(e: number) {
    let time = this.timesExperience.filter((t) => t.id == e);
    return time[0].name;
  }

  editPicture() {
    let inputImage = document.querySelector('.input-image');
    // @ts-ignore
    inputImage.click();
  }

  changePicture(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e: any) {
    // @ts-ignore
    this.user.picture = 'data:image/png;base64,' + btoa(e.target.result);
    this.updateUser();
  }
  openModalEditHabilidades() {
    this.bsModalRef = this.modalService.show(ModalHabilidadesComponent, {
      backdrop: true,
    });
  }
}
