import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  debounceTime,
  delay,
  first,
  last,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directive/sortable.directive';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../../../models/user/user';

interface SearchResult {
  users: User[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  access: number;
  active: number;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

@Injectable({ providedIn: 'root' })
export class UsuarioHomeLifeTableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _users$ = new BehaviorSubject<User[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    access: 0,
    active: 0,
    sortColumn: '',
    sortDirection: '',
  };

  constructor(private userService: UserService) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search(0, 0)),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._users$.next(result.users);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get users$() {
    return this._users$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get access() {
    return this._state.access;
  }
  get active() {
    return this._state.active;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set access(access: number) {
    this._set({ access });
  }
  set active(active: number) {
    this._set({ active });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  public _search(access: number, active: number): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    const searchParams = {
      page,
      pageSize,
      searchTerm,
      access,
      active,
      sortColumn,
      sortDirection,
    };

    return this.userService.getListWithParams(searchParams).pipe(
      first(),
      map((result: any) => {
        return {
          users: result.body.content,
          total: result.body.totalElements,
        };
      })
    );
  }

  reset() {
    this._search$
      .pipe(
        first(),
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search(0, 0)),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._users$.next(result.users);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  filterAccessAndActiveUsers(access: number, active: number) {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    const searchParams = {
      page,
      pageSize,
      searchTerm,
      access,
      active,
      sortColumn,
      sortDirection,
    };

    return this.userService
      .getListWithParams(searchParams)
      .pipe(first())
      .subscribe((result) => {
        this._users$.next(result.body.content);
        this._total$.next(result.body.totalElements);
      });
  }
}
