import { Component, QueryList, ViewChildren } from '@angular/core';
import { User } from '../../../models/user/user';
import { UserService } from 'src/app/services/user.service';
import { UsuarioHomeLifeUiDTO } from './dto/usuario-homelife-ui.dto';
import { Observable, first } from 'rxjs';
import { UtilsModule } from 'src/app/utils';
import {
  NgbModal,
  NgbModalOptions,
  NgbPaginationModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { FormsModule } from '@angular/forms';
import { last, map } from 'rxjs/operators';
import { NgbdSortableHeader, SortEvent } from './directive/sortable.directive';
import { UsuarioHomeLifeTableService } from './service/usuario-homelife-table.service';
import { AsyncPipe, DecimalPipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-usuarios-homelife',
  templateUrl: './usuarios-homelife.component.html',
  styleUrls: [
    './usuarios-homelife.component.scss',
    '../../../../darkmode.scss',
  ],
  standalone: true,
  imports: [
    NgFor,
    DecimalPipe,
    FormsModule,
    AsyncPipe,
    NgbTypeaheadModule,
    NgbdSortableHeader,
    NgbPaginationModule,
    NgIf,
  ],
  providers: [UsuarioHomeLifeTableService, DecimalPipe, UserService],
})
export class UsuariosHomeLifeComponent {
  protected readonly UtilsModule = UtilsModule;

  usuarioHomeLifeUiDTO: UsuarioHomeLifeUiDTO = new UsuarioHomeLifeUiDTO();

  users$: Observable<User[]>;
  total$: Observable<number>;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: UsuarioHomeLifeTableService,
    private modalService: NgbModal,
    private toastService: NgToastService,
    private userService: UserService
  ) {
    this.headers = new QueryList<NgbdSortableHeader>();
    this.users$ = service.users$;
    this.total$ = service.total$;
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  openModalAccess(content: any, userSelected: User) {
    const modalOptions: NgbModalOptions = {
      centered: true,
    };

    this.modalService.open(content, modalOptions);
    this.usuarioHomeLifeUiDTO.userSelected = userSelected;
  }

  openModalActive(content: any, userSelected: User) {
    const modalOptions: NgbModalOptions = {
      centered: true,
    };

    this.modalService.open(content, modalOptions);
    this.usuarioHomeLifeUiDTO.userSelected = userSelected;
  }

  saveAccess() {
    this.userService
      .save(this.usuarioHomeLifeUiDTO.userSelected)
      .pipe(first())
      .subscribe({
        next: (data) => {
          if (data.status == 201) {
            this.toastService.success({
              summary: 'Informações De Administrador Atualizadas.',
              duration: 5000,
              position: 'tr',
            });

            this.modalService.dismissAll();
          }
        },
        error: (error: any) => {
          this.toastService.error({
            summary: 'Erro: ' + error.toString(),
            duration: 5000,
            position: 'tr',
          });
        },
      });
  }

  saveActive() {
    this.userService
      .save(this.usuarioHomeLifeUiDTO.userSelected)
      .pipe(first())
      .subscribe({
        next: (data) => {
          if (data.status == 201) {
            this.toastService.success({
              summary: 'Informações De Ativo Atualizadas.',
              duration: 5000,
              position: 'tr',
            });

            this.modalService.dismissAll();
          }
        },
        error: (error: any) => {
          this.toastService.error({
            summary: 'Erro: ' + error.toString(),
            duration: 5000,
            position: 'tr',
          });
        },
      });
  }
}
