import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './models/user/user';
import { UserLogged } from './services/user-logged';
import { LoginService } from './services/login.service';
import { ApiService } from './services/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilsModule } from './utils';
import { AppService } from './app.service';
import { environment } from './environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../darkmode.scss'],
})
export class AppComponent implements OnInit {
  title = 'HomeLife';
  public user: User;
  public token: string;
  private jwt = localStorage.getItem('jwt');
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userLogged: UserLogged,
    private loginService: LoginService,
    private apiService: ApiService,
    private jwtHelper: JwtHelperService,
    private appService: AppService
  ) {
    this.user = userLogged.user;
    this.token = <string>localStorage.getItem('jwt');
    if (this.user == null && this.token != null) this.loginService.logout();
  }

  ngOnInit(): void {
    if (this.user.id != null) {
      this.getUser();
    }
  }

  async getUser() {
    let user: User = JSON.parse(<string>localStorage.getItem('user'));
    let expirationDate: Date = <Date>(
      this.jwtHelper.getTokenExpirationDate(this.token)
    );
    let diffTime = UtilsModule.calcularMinutos(expirationDate);
    /*if(diffTime >= 0 && environment.production) {
      this.loginService.logout();
    }*/
    this.user = await this.loginService.getUser(this.user.id);
    this.user.currency = await this.apiService
      .get(ApiService.URL + 'user/currency/' + this.user.id)
      .toPromise();
    this.userLogged.user = this.user;
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('jwt', this.user.token.toString());
  }

  public showMenu(): boolean {
    let url = this.router.url;
    if (url == '/feed') return true;
    if (this.jwt == null && url == '') return false;
    if (url == '/' && this.jwt == null) return false;
    if (url == '/job') return true;
    if (url == '/anuncio/novo') return false;
    if (url == '/anuncio/editar') return false;
    if (url == '/perfil') return true;
    if (url.includes('/admin')) return false;
    if (url.includes('/meus-anuncios')) return false;
    if (url.includes('/inbox')) return false;
    if (url.includes('/carteira')) return false;
    if (url.includes('/perfil/edit')) return false;
    if (url.includes('/leilao/modelo')) return false;
    if (url.includes('/como-funciona')) return false;
    if (url.includes('/checkout/garantia')) return false;
    if (url.includes('/checkout/plano')) return false;
    if (url.includes('/termos')) return false;
    if (url.includes('/leiloes/feed')) return true;
    if (url.includes('/notificacoes')) return true;
    if (url.includes('fbclid')) return false;
    // @ts-ignore
    if (
      this.router.config.filter((r) => r.path?.includes(url.substring(1)))
        .length == 0
    )
      return true;
    return true;
  }

  public showHeader(): boolean {
    let url = this.router.url;
    if (url == '/feed') return true;
    if (
      this.jwt == null &&
      (url == '/' ||
        url == '/como-funciona' ||
        url == '' ||
        url.includes('fbclid'))
    ) {
      return false;
    }
    return true;
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    e.preventDefault();
    this.appService.promptEvent = e;
  }
}
