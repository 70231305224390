import { Component, OnInit } from '@angular/core';
import { CategoriaService } from '../../services/categoria.service';
import { Categoria } from '../../models/categoria/categoria';
import { Router } from '@angular/router';
import { CadastroComponent } from '../modais/cadastro/cadastro.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpParameterCodec } from '@angular/common/http';
import { UtilsModule } from '../../utils';
import { Meta, Title } from '@angular/platform-browser';
import { ControllerCarouselService } from '../../services/controller-carousel.service';
import { UserLogged } from '../../services/user-logged';
import { User } from '../../models/user/user';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', '../../../darkmode.scss'],
})
export class MenuComponent implements OnInit {
  public user: User;
  public categoriasNivel0: Categoria[] = [];
  public categoriaFilter: any;
  public filterTipoCategoria: any;
  public idExpanded: number = 0;
  public state: boolean = false;
  public isLogged: boolean = false;
  constructor(
    private categoriaService: CategoriaService,
    public routes: Router,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private meta: Meta,
    private title: Title,
    private _controllerCarouselService: ControllerCarouselService,
    private userLogged: UserLogged
  ) {
    this.filterTipoCategoria = localStorage.getItem('tipoCategoriaFilter');
    this.categoriaFilter = localStorage.getItem('categoriaFilter');
    this.idExpanded = parseInt(this.categoriaFilter);
    if (this.categoriaFilter != undefined && this.categoriaFilter != null)
      this.categoriaFilter = parseInt(this.categoriaFilter);
    else this.categoriaFilter = 0;
    this._controllerCarouselService.state.subscribe((s) => (this.state = s));
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
  }

  public async ngOnInit() {
    await this.loadCategorias();
  }

  async loadCategorias() {
    let tipoCategoria = -1;
    if (this.filterTipoCategoria != null)
      tipoCategoria = parseInt(this.filterTipoCategoria);
    this.categoriasNivel0 = await this.categoriaService
      .getByNivel(0, tipoCategoria)
      .toPromise();
    this.categoriasNivel0.map(async (c) => {
      c.filhas = await this.categoriaService
        .getByCategoriaPai(c.id)
        .toPromise();
      if (this.categoriaFilter != undefined && this.categoriaFilter != null) {
        if (c.filhas != null) {
          let cat = c.filhas.filter((c1) => c1.id == this.categoriaFilter);
          if (cat.length > 0) {
            this.loadMetaTags(cat[0]);
          }
        }
      }
    });
  }

  public filterCategoria(categoria: Categoria) {
    let name = UtilsModule.accentsTidy(
      categoria.nome.replaceAll(' ', '-')
    ).toLowerCase();
    let name2 = UtilsModule.accentsTidy(
      categoria.categoriaPaiNome.replaceAll(' ', '-')
    ).toLowerCase();
    localStorage.setItem('typeFilter', 'categoria');
    localStorage.setItem('categoriaFilter', String(categoria.id));
    localStorage.setItem('categoriaNomeFilter', categoria.nome);
    localStorage.setItem('categoriaPaiNomeFilter', categoria.categoriaPaiNome);
    this.idExpanded = categoria.id;
    window.location.href = this.routes
      .createUrlTree([name2 + '-' + name])
      .toString();
  }

  public selectTipoCategoria(tipoCategoria: number) {
    localStorage.removeItem('categoriaFilter');
    localStorage.removeItem('tipoCategoriaFilter');
    localStorage.removeItem('searchFilter');
    localStorage.removeItem('cidadeFilter');
    localStorage.removeItem('kmFilter');
    localStorage.removeItem('typeFilter');
    localStorage.setItem('typeFilter', 'tipoCategoria');
    localStorage.setItem('tipoCategoriaFilter', tipoCategoria.toString());
    window.location.href = this.routes
      .createUrlTree([tipoCategoria == 0 ? 'servicos' : 'projetos'])
      .toString();
  }

  public isFilha(id: number, catPai: Categoria) {
    let filha;
    if (catPai.filhas != null) filha = catPai.filhas.find((c) => c.id == id);
    return filha != undefined;
  }

  public openModalCadastro() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(CadastroComponent, {
      backdrop: true,
    });
  }

  public loadMetaTags(categoria: Categoria) {
    this.meta.addTag({ name: 'og:url', content: window.location.href });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'canonical', content: window.location.href });
    let node = document.createElement('link');
    node.rel = 'canonical';
    node.href = window.location.href;
    document.getElementsByTagName('head')[0].appendChild(node);
    let title = categoria.nome + ' | HomeLife';
    this.title.setTitle(title);
    this.meta.addTag({ name: 'og:title', content: title });
    this.meta.addTag({ name: 'title' });
    this.meta.addTag({
      name: 'og:description',
      content: `"Encontre freelancer de ${categoria.nome}. Encontre trabalho prestador de serviço de ${categoria.nome}. Encontre ofertas emprego de ${categoria.nome} | HomeLife."`,
    });
    this.meta.addTag({
      name: 'description',
      content: `"Encontre freelancer de ${categoria.nome}. Encontre trabalho prestador de serviço de ${categoria.nome}. Encontre ofertas emprego de ${categoria.nome} | HomeLife."`,
    });
    this.meta.addTag({ name: 'og:image', content: 'favicon.ico' });
  }

  public showExplorar() {
    this._controllerCarouselService.changeState(!this.state);
  }

  public categoriasZeroOrdem(): Categoria[] {
    if (this.categoriasNivel0 != null && this.categoriasNivel0.length > 0)
      return this.categoriasNivel0
        .sort((a, b) => a.nome.localeCompare(b.nome))
        .slice(0, 10);
    else return [];
  }

  public categoriasUmOrdem() {
    let arrCatUmOrdem = [];
    for (let cat0 of this.categoriasNivel0) {
      arrCatUmOrdem.push(cat0.filhas.slice().sort());
    }
    return arrCatUmOrdem;
  }

  public categoriaUmOrdem(filhas: Categoria[]): Categoria[] {
    if (filhas != null)
      return filhas.sort((a, b) => a.nome.localeCompare(b.nome));
    else return filhas;
  }

  public outrasCategorias(): Categoria[] {
    if (this.categoriasNivel0 != null && this.categoriasNivel0.length > 0)
      return this.categoriasNivel0.slice(10, this.categoriasNivel0.length);
    else return [];
  }
}
