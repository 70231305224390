import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './pages/header/header.component';
import { MenuComponent } from './pages/menu/menu.component';
import { HomeComponent } from './pages/home/home.component';
import { DownloadappComponent } from './pages/modais/downloadapp/downloadapp.component';
import { SenhaComponent } from './pages/modais/senha/senha.component';
import { CadastroComponent } from './pages/modais/cadastro/cadastro.component';
import { LoginComponent } from './pages/modais/login/login.component';
import { NgToastModule } from 'ng-angular-popup';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ControleMenuComponent } from './pages/componentes/controle-menu/controle-menu.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AnuncioComponent } from './pages/anuncio/anuncio.component';
import { AuthGuard } from './auth.guard';
import { ImagemUserComponent } from './pages/componentes/imagem-user/imagem-user.component';
import { FeedPostsComponent } from './pages/componentes/feed-posts/feed-posts.component';
import { ControleFeedComponent } from './pages/componentes/controle-feed/controle-feed.component';
import { AsideWelcomeComponent } from './pages/componentes/aside-welcome/aside-welcome.component';
import { NovoAnuncioComponent } from './pages/anuncio/novo-anuncio/novo-anuncio.component';
import { DropUserComponent } from './pages/componentes/drop-user/drop-user.component';
import { MeusAnunciosComponent } from './pages/anuncio/meus-anuncios/meus-anuncios.component';
import { PropostaComponent } from './pages/proposta/proposta.component';
import { NovaPropostaComponent } from './pages/proposta/nova-proposta/nova-proposta.component';
import { VerPropostaComponent } from './pages/proposta/ver-proposta/ver-proposta.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { CarteiraComponent } from './pages/carteira/carteira.component';
import { TermosComponent } from './pages/termos/termos.component';
import { ControleMenuHomeComponent } from './pages/componentes/controle-menu-home/controle-menu-home.component';
import { CheckoutComponent } from './pages/payment/checkout/checkout.component';
import { UtilsModule } from './utils';
import { environment } from './environments/environment';
import { LoaderComponent } from './pages/componentes/loader/loader.component';
import { NgxLoadingModule } from 'ngx-loading';
import { LoaderModule } from './pages/componentes/loader/loader.module';
import { LoaderInterceptor } from './pages/componentes/loader/loader.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlanosComponent } from './pages/componentes/planos/planos.component';
import { FormsNovoAnuncioComponent } from './pages/componentes/forms-novo-anuncio/forms-novo-anuncio.component';
import { ModelNovoAnuncioComponent } from './pages/componentes/model-novo-anuncio/model-novo-anuncio.component';
import { MapaComponent } from './pages/componentes/mapa/mapa.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InboxModule } from './pages/inbox/inbox.module';
import { AdminModule } from './pages/admin/admin.module';
import { NbIconModule, NbLayoutModule, NbStatusService } from '@nebular/theme';
import { ChatService } from './services/chat.service';
import { AceitePropostaComponent } from './pages/modais/aceite-proposta/aceite-proposta.component';
import { DepositoGarantiaComponent } from './pages/deposito-garantia/deposito-garantia.component';
import { SitemapComponent } from './pages/componentes/sitemap/sitemap.component';
import { PropostaSelecionadaComponent } from './pages/proposta/proposta-selecionada/proposta-selecionada.component';
import { EscolhaFreelancerComponent } from './pages/modais/escolha-freelancer/escolha-freelancer.component';
import { CarrosselLeiloesComponent } from './pages/componentes/carrossel-leiloes/carrossel-leiloes.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { TimeCounterComponent } from './pages/componentes/time-counter/time-counter.component';
import { ModelPerguntaComponent } from './pages/anuncio/model-pergunta/model-pergunta.component';
import { FeedLeiloesComponent } from './pages/componentes/feed-leiloes/feed-leiloes.component';
import { MeuAnuncioCardComponent } from './pages/anuncio/meu-anuncio-card/meu-anuncio-card.component';
import { MeusAnunciosFilterComponent } from './pages/anuncio/meus-anuncios-filter/meus-anuncios-filter.component';
import { PaginationComponent } from './pages/componentes/pagination/pagination.component';
import { PostMenuComponent } from './pages/componentes/post-menu/post-menu.component';
import { LeiloesComponent } from './pages/leiloes/leiloes.component';
import { FilterLeiloesComponent } from './pages/componentes/filter-leiloes/filter-leiloes.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LeilaoPaginaComponent } from './pages/leilao-pagina/leilao-pagina.component';
import { ControllerCarouselService } from './services/controller-carousel.service';
import { CompartilharComponent } from './pages/componentes/compartilhar/compartilhar.component';
import { ModalCancelaAnuncioComponent } from './pages/componentes/modal-cancela-anuncio/modal-cancela-anuncio.component';
import { AgmCoreModule } from '@agm/core';
import { NewPostButtonComponent } from './pages/componentes/new-post-button/new-post-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddHabilidadesComponent } from './pages/componentes/add-habilidades/add-habilidades.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ConfirmCancelPlanComponent } from './pages/modais/confirm-cancel-plan/confirm-cancel-plan.component';
import { ConfirmChangePlanComponent } from './pages/modais/confirm-change-plan/confirm-change-plan.component';
import { NotificacoesComponent } from './pages/perfil/notificacoes/notificacoes.component';
import { ImageAnuncioComponent } from './pages/modais/image-anuncio/image-anuncio.component';
import { AvaliacoesComponent } from './pages/modais/avaliacoes/avaliacoes.component';
import { PromoverAnuncioComponent } from './pages/modais/promover-anuncio/promover-anuncio.component';
import { ModalPerguntasComponent } from './pages/modais/modal-perguntas/modal-perguntas.component';
import { FeedComponent } from './pages/feed/feed.component';
import { HamburgerMenuComponent } from './pages/componentes/hamburger-menu/hamburger-menu.component';
import { MenuMobileComponent } from './pages/componentes/menu-mobile/menu-mobile.component';
import { NgOptimizedImage } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ComoFuncionaHomeLifeComponent } from './pages/como-funciona-homelife/como-funciona-homelife.component';
import { UpdateEnderecoComponent } from './pages/componentes/update-endereco/update-endereco.component';
import { UserService } from './services/user.service';
import { ModalHabilidadesComponent } from './pages/modais/modal-habilidades/modal-habilidades.component';
import { LogoSliderComponent } from './pages/componentes/logo-slider/logo-slider.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ComoFuncionaHomeLifeComponent,
    HeaderComponent,
    MenuComponent,
    DownloadappComponent,
    SenhaComponent,
    CadastroComponent,
    LoginComponent,
    AnuncioComponent,
    ImagemUserComponent,
    FeedPostsComponent,
    ControleFeedComponent,
    AsideWelcomeComponent,
    NovoAnuncioComponent,
    DropUserComponent,
    MeusAnunciosComponent,
    PropostaComponent,
    NovaPropostaComponent,
    VerPropostaComponent,
    PerfilComponent,
    CarteiraComponent,
    TermosComponent,
    ControleMenuHomeComponent,
    CheckoutComponent,
    PlanosComponent,
    FormsNovoAnuncioComponent,
    ModelNovoAnuncioComponent,
    MapaComponent,
    AceitePropostaComponent,
    DepositoGarantiaComponent,
    SitemapComponent,
    PropostaSelecionadaComponent,
    EscolhaFreelancerComponent,
    CarrosselLeiloesComponent,
    TimeCounterComponent,
    ModelPerguntaComponent,
    FeedLeiloesComponent,
    MeuAnuncioCardComponent,
    MeusAnunciosFilterComponent,
    PaginationComponent,
    PostMenuComponent,
    LeiloesComponent,
    FilterLeiloesComponent,
    LeilaoPaginaComponent,
    ModalCancelaAnuncioComponent,
    NewPostButtonComponent,
    AddHabilidadesComponent,
    ConfirmCancelPlanComponent,
    ConfirmChangePlanComponent,
    NotificacoesComponent,
    ImageAnuncioComponent,
    AvaliacoesComponent,
    PromoverAnuncioComponent,
    ModalPerguntasComponent,
    FeedComponent,
    ControleMenuComponent,
    HamburgerMenuComponent,
    MenuMobileComponent,
    UpdateEnderecoComponent,
    ModalHabilidadesComponent,
    LogoSliderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbDropdownModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    FormsModule,
    UtilsModule,
    ReactiveFormsModule,
    NgToastModule,
    NgxLoadingModule,
    LoaderModule,
    CKEditorModule,
    InboxModule,
    NbLayoutModule,
    AdminModule,
    NbIconModule,
    MatDialogModule,
    IvyCarouselModule,
    MatInputModule,
    MatDatepickerModule,
    CurrencyMaskModule,
    CompartilharComponent,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
    }),
    NgOptimizedImage,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    ChatService,
    NbStatusService,
    ControllerCarouselService,
    UserService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],
  exports: [MenuComponent, ControleMenuHomeComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
