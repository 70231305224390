import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnuncioComponent } from './pages/anuncio/anuncio.component';
import { AuthGuard } from './auth.guard';
import { NovoAnuncioComponent } from './pages/anuncio/novo-anuncio/novo-anuncio.component';
import { CheckoutComponent } from './pages/payment/checkout/checkout.component';
import { PropostaComponent } from './pages/proposta/proposta.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { TermosComponent } from './pages/termos/termos.component';
import { NovaPropostaComponent } from './pages/proposta/nova-proposta/nova-proposta.component';
import { MeusAnunciosComponent } from './pages/anuncio/meus-anuncios/meus-anuncios.component';
import { CarteiraComponent } from './pages/carteira/carteira.component';
import { DepositoGarantiaComponent } from './pages/deposito-garantia/deposito-garantia.component';
import { SitemapComponent } from './pages/componentes/sitemap/sitemap.component';
import { PropostaSelecionadaComponent } from './pages/proposta/proposta-selecionada/proposta-selecionada.component';
import { FeedLeiloesComponent } from './pages/componentes/feed-leiloes/feed-leiloes.component';
import { LeilaoPaginaComponent } from './pages/leilao-pagina/leilao-pagina.component';
import { NotificacoesComponent } from './pages/perfil/notificacoes/notificacoes.component';
import { HomeComponent } from './pages/home/home.component';
import { FeedComponent } from './pages/feed/feed.component';
import * as url from 'url';
import { ComoFuncionaHomeLifeComponent } from './pages/como-funciona-homelife/como-funciona-homelife.component';

const routes: Routes = [
  {
    path: '',
    component: (() => {
      let jwt = localStorage.getItem('jwt');
      if (jwt == null) return HomeComponent;
      else return FeedComponent;
    })(),
  },
  { path: 'sitemap.xml', component: SitemapComponent },
  { path: 'feed', component: FeedComponent },
  { path: 'job', component: AnuncioComponent },
  { path: 'job/:parameter', component: AnuncioComponent },
  {
    path: 'anuncio/novo',
    component: NovoAnuncioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'anuncio/editar',
    component: NovoAnuncioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'anuncio/meus-anuncios',
    component: MeusAnunciosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment/checkout',
    component: CheckoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'checkout/garantia',
    component: DepositoGarantiaComponent,
    canActivate: [AuthGuard],
  },
  { path: 'propostas', component: PropostaComponent, canActivate: [AuthGuard] },
  {
    path: 'proposta/new',
    component: NovaPropostaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'proposta/edit',
    component: NovaPropostaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'proposta/selecionada',
    component: PropostaSelecionadaComponent,
    canActivate: [AuthGuard],
  },
  { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard] },
  {
    path: 'client/:parameter',
    component: PerfilComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'freelancer/:parameter',
    component: PerfilComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'perfil/edit',
    component: CarteiraComponent,
    canActivate: [AuthGuard],
  },
  { path: 'carteira', component: CarteiraComponent, canActivate: [AuthGuard] },
  { path: 'termos', component: TermosComponent },
  {
    path: 'notificacoes',
    component: NotificacoesComponent,
    canActivate: [AuthGuard],
  },
  // {path: 'como-funciona', loadChildren: () => import('./pages/como-funciona-homelife/como-funciona-homelife.component').then(m => m.ComoFuncionaHomeLifeComponent)},
  { path: 'como-funciona', component: ComoFuncionaHomeLifeComponent },
  {
    path: 'checkout/plano',
    loadChildren: () =>
      import('./pages/checkout-planos/checkout-planos.module').then(
        (m) => m.CheckoutPlanosModule
      ),
  },

  {
    path: ':parameter',
    component: (() => {
      return FeedComponent;
    })(),
  },
  {
    path: 'leiloes/feed',
    component: FeedLeiloesComponent,
    canActivate: [AuthGuard],
  },
  // {path: 'leilao/modelo', component: LeilaoPaginaComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
