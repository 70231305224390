import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../../../services/chat.service';
import { ChatDTO } from '../../../models/chat/chatDTO';
import { WebSocketAPI } from '../../../services/WebSocketAPI';
import { ChatForm } from '../../../models/chat/chatForm';
import { Conversation } from '../../../models/chat/conversation';
import { User } from '../../../models/user/user';
import { UserLogged } from '../../../services/user-logged';
import { ChatModel } from '../../../models/chat/chatModel';
import { interval, Observable } from 'rxjs';
import { UtilsModule } from '../../../utils';
import { LoginService } from '../../../services/login.service';
import { AceitePropostaComponent } from '../../modais/aceite-proposta/aceite-proposta.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AnuncioFeed } from '../../../models/anuncio/anuncioFeed';
import { AnuncioService } from '../../../services/anuncio.service';
import { NgToastService } from 'ng-angular-popup';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { PlaySoundService } from '../../../services/play-sound.service';
import { AvaliacoesComponent } from '../../modais/avaliacoes/avaliacoes.component';
import { Proposta } from 'src/app/models/anuncio/proposta';
import { UserModel } from '../../../models/user/userModel';
import { AnuncioFeedModel } from '../../../models/anuncio/anuncioFeedModel';
import * as util from 'util';
import { PropostaModel } from '../../../models/anuncio/propostaModel';
import { ConversationModel } from '../../../models/chat/conversationModel';
import { ConversationAnexo } from '../../../models/chat/conversationAnexo';
import { ConversationAnexoModel } from '../../../models/chat/conversationAnexoModel';

@Component({
  selector: 'home-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss', '../../../../darkmode.scss'],
})
export class ChatComponent implements OnInit {
  public message: string = '';
  public chat: ChatDTO[] = new Array<ChatDTO>();
  public conversation: Conversation = new ConversationModel();
  public proposta: Proposta = new PropostaModel();
  public anuncio: AnuncioFeed = new AnuncioFeedModel();
  public user: User = new UserModel();
  public sender: number = 0;
  public chatingWith: User = new UserModel();
  public totalUnread: number = 0;
  public sending = false;
  public readonly UtilsModule = UtilsModule;
  public isEmojiPickerVisible: boolean = false;

  constructor(
    private chatService: ChatService,
    private router: Router,
    private loginService: LoginService,
    private userLogged: UserLogged,
    private activatedRouter: ActivatedRoute,
    private webSocketAPI: WebSocketAPI,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public anuncioService: AnuncioService,
    private toast: NgToastService,
    private dialog: MatDialog
  ) {
    this.user = userLogged.user;
    this.conversation = JSON.parse(
      <string>localStorage.getItem('currentConversation')
    );
    if (this.conversation != null) this.anuncio = this.conversation.anuncio;
  }

  ngOnInit(): void {
    this.getChats();
    this.webSocketAPI.conversation.subscribe((conversation) => {
      this.conversation = conversation;
      this.getChats();
      this.loadAnuncioProposta();
    });
  }

  loadAnuncioProposta() {
    if (
      this.conversation != null &&
      this.conversation.proposta != null &&
      this.conversation.proposta.id != null
    ) {
      localStorage.setItem(
        'proposta',
        JSON.stringify(this.conversation.proposta)
      );
      this.proposta = JSON.parse(<string>localStorage.getItem('proposta'));
    }
  }

  public async getChats() {
    if (this.conversation != null) {
      this.conversation = await this.chatService.getConversation(
        this.conversation.id
      );
      this.sender =
        this.conversation.user1Id == this.user?.id
          ? this.conversation.user2Id
          : this.conversation.user1Id;
      this.getProfile();
      this.chatService.getChat(this.conversation).subscribe((c) => {
        this.chat = c;
        this.connect();
        let s = this.sender;
        interval(5000).subscribe((val: number) => {
          this.checkNewMessages(this.conversation.id, s, this.user.id);
        });
      });
      this.webSocketAPI.chat.subscribe((chat) => {
        this.message = '';
        this.sending = false;
        let readed = this.chat.find((c) => c.id == chat.id);
        if (!readed && chat.conversation.id == this.conversation.id) {
          this.chat.push(chat);
          if (chat.sender != this.user?.id) {
            this.webSocketAPI.readNewMessage(chat.id);
          }
        }
      });
    }
  }

  connect() {
    // @ts-ignore
    this.webSocketAPI._connect();
  }

  disconnect() {
    this.webSocketAPI._disconnect();
  }

  sendMessage() {
    if (
      this.user != undefined &&
      this.message != null &&
      this.message != '' &&
      !this.sending
    ) {
      this.sending = true;
      let messageForm: ChatForm = new ChatModel();
      messageForm.message = this.message;
      messageForm.user1Id = this.conversation.user1Id;
      messageForm.user2Id = this.conversation.user2Id;
      messageForm.sender = this.user.id;
      messageForm.anuncioId = this.conversation.anuncio.id;
      this.webSocketAPI._send(messageForm);
    }
  }

  private checkNewMessages(id: number, sender: number, receiver: number) {
    this.webSocketAPI.checkNewMessages(id, sender, receiver);
  }

  public melhorarProposta() {
    localStorage.setItem(
      'proposta',
      JSON.stringify(this.conversation.proposta)
    );
    if (this.conversation.anuncio != null)
      localStorage.setItem(
        'viewAnuncio',
        JSON.stringify(this.conversation.anuncio)
      );
    else
      localStorage.setItem(
        'viewAnuncio',
        JSON.stringify(this.conversation.proposta.anuncio)
      );
    this.router.navigate(['proposta/edit']);
  }

  public entregarTrabalho() {
    if (this.conversation.anuncio != null)
      localStorage.setItem(
        'viewAnuncio',
        JSON.stringify(this.conversation.anuncio)
      );
    else
      localStorage.setItem(
        'viewAnuncio',
        JSON.stringify(this.conversation.proposta.anuncio)
      );
    localStorage.setItem(
      'proposta',
      JSON.stringify(this.conversation.proposta)
    );
    this.bsModalRef = this.modalService.show(AvaliacoesComponent, {
      backdrop: true,
    });
  }

  async getProfile() {
    this.chatingWith = await this.loginService.getUser(this.sender);
  }

  async showProfile() {
    localStorage.setItem('userProfile', JSON.stringify(this.chatingWith));
    // @ts-ignore
    let name = UtilsModule.accentsTidy(
      this.chatingWith.name.replaceAll(' ', '-')
    ).toLowerCase();
    window.location.href = this.router
      .createUrlTree(['client/' + name])
      .toString();
  }

  openModalAceiteProposta(proposta: Proposta) {
    localStorage.setItem('proposta', JSON.stringify(proposta));
    this.bsModalRef = this.modalService.show(AceitePropostaComponent);
  }

  public fazerProposta() {
    if (this.user.currency.value == 0) {
      this.toast.info({
        detail: 'INFO',
        summary: 'Necessário adquirir HomeLife para realizar propostas.',
        duration: 5000,
        position: 'tr',
      });
    } else {
      localStorage.setItem(
        'viewAnuncio',
        JSON.stringify(this.conversation.anuncio)
      );
      this.router.navigate(['proposta/new']);
    }
  }

  openGarantia() {
    localStorage.setItem(
      'proposta',
      JSON.stringify(this.conversation.proposta)
    );
    window.location.href = 'checkout/garantia';
  }

  // Titulo Modelo - Não foi possível carregar o título do banco.
  public titulo: string = 'Converter figma para html';
  public tituloAnuncioMin() {
    if (this.titulo.length > 50) {
      return this.titulo.substring(0, 50) + '...';
    } else {
      return this.titulo;
    }
  }

  public hourMessage() {
    let dataMensagem = new Date();
    return dataMensagem.getHours();
  }

  inativePublication(anuncio: AnuncioFeed) {
    this.anuncioService.inativatePublication(anuncio.id);
  }

  edit(): void {
    const data = {
      idAnuncio: this.anuncio?.id,
    };
    this.router.navigate(['/anuncio/editar'], { state: data });
  }

  deleteAnexo(id: number) {
    this.chatService.deleteAnexo(id);
    this.conversation.anexos = this.conversation.anexos.filter(
      (a) => a.id != id
    );
  }

  handleReaderLoaded(e: any) {
    let _this = this;
    // @ts-ignore
    let file = 'data:image/png;base64,' + btoa(e.target.result);
    let anexo: ConversationAnexo = new ConversationAnexoModel();
    anexo.file = file;
    this.chatService
      .saveAnexo(anexo, this.conversation.id)
      .subscribe((anexoId) => {
        anexo.id = anexoId;
        if (_this.conversation.anexos == null) _this.conversation.anexos = [];
        _this.conversation.anexos.push(anexo);
      });
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  public addEmoji(event: any) {
    this.message = `${this.message}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  protected readonly console = console;
}
