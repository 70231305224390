import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/user/user';
import { AnuncioFeed } from '../models/anuncio/anuncioFeed';
import { Observable } from 'rxjs';
import { Sistema } from '../models/sistem.enum';

@Injectable({
  providedIn: 'root',
})
export class AnuncioService {
  private urlAnuncio = ApiService.URL + 'anuncio/';
  private urlProposta = ApiService.URL + 'proposta/';
  static itensPerPage: number = environment.itensPerPage;

  constructor(private routes: Router, private service: ApiService) {}

  public getList(page: number, itens: number, typeFilter: string, filter: any) {
    if (filter == undefined || filter == null)
      return this.service.get(
        this.urlAnuncio + page + '/' + itens + '/' + Sistema.HOMELIFE
      );
    else
      return this.service.get(
        this.urlAnuncio +
          page +
          '/' +
          itens +
          '/' +
          typeFilter +
          '/' +
          filter +
          '/' +
          Sistema.HOMELIFE
      );
  }

  public salvar(anuncio: any) {
    this.service.post(ApiService.URL + 'anuncio', anuncio).subscribe((a) => {
      if (a.code == undefined) {
        // @ts-ignore
        cadastro_projetos_gtag();
        localStorage.setItem('viewAnuncio', JSON.stringify(a));
        this.routes.navigate(['job']);
      }
    });
  }

  async get(idAnuncio: any) {
    return await this.service.get(this.urlAnuncio + idAnuncio).toPromise();
  }

  async getCurrentValuePromotion(day1: number, day2: number) {
    return await this.service
      .get(
        this.urlAnuncio +
          'valuepromotion/' +
          day1 +
          '/' +
          day2 +
          '/' +
          Sistema.HOMELIFE
      )
      .toPromise();
  }

  getById(idAnuncio: number): Observable<any> {
    return this.service.get(this.urlAnuncio + idAnuncio);
  }

  adicionarInteressado(user: User, anuncio: AnuncioFeed, question: string) {
    let addInteressado = {
      userId: user.id,
      anuncioId: anuncio.id,
      message: question,
    };
    this.service
      .post(this.urlAnuncio + 'addinteressado', addInteressado)
      .toPromise();
  }

  async getImagens(id: number) {
    return this.service.get(this.urlAnuncio + 'imagens/' + id).toPromise();
  }

  async getInfos(id: number) {
    return this.service
      .get(this.urlAnuncio + 'infos/' + id + '/' + Sistema.HOMELIFE)
      .toPromise();
  }

  async getProposta(id: number) {
    return this.service.get(this.urlProposta + id).toPromise();
  }

  async setPropostaAceita(id: number) {
    return this.service.get(this.urlProposta + 'aceita/' + id).toPromise();
  }

  async getUserAnuncios(idUser: number) {
    return this.service
      .get(
        this.urlAnuncio +
          'user?idUser=' +
          idUser +
          '&sistema=' +
          Sistema.HOMELIFE
      )
      .toPromise();
  }

  async getMediaPropostas(id: number) {
    return this.service.get(this.urlAnuncio + 'media/' + id).toPromise();
  }

  async mudaPrivado(idAnuncio: number) {
    return this.service
      .put(this.urlAnuncio + idAnuncio + '/privado', {})
      .toPromise();
  }

  async mudaStatus(
    idAnuncio: number,
    status: number,
    motivo: string | undefined
  ) {
    return this.service
      .put(this.urlAnuncio + idAnuncio + '/status/' + status, {
        motivoCancelamento: motivo,
      })
      .toPromise();
  }

  async inativatePublication(idAnuncio: number) {
    return this.service
      .put(this.urlAnuncio + 'inativate/' + idAnuncio, {})
      .toPromise();
  }

  async promoverAnuncio(id: number, day1: number, day2: number) {
    await this.service
      .put(
        this.urlAnuncio +
          'promover/' +
          id +
          '/' +
          day1 +
          '/' +
          day2 +
          '/' +
          Sistema.HOMELIFE,
        {}
      )
      .toPromise();
  }
}
