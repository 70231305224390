import { User } from '../../../../models/user/user';
import { UserModel } from '../../../../models/user/userModel';

export class UsuarioHomeLifeUiDTO {
  page = 1;
  pageSize = 10;
  collectionSize = 0;

  userSelected: UserModel = new UserModel();
  users: Array<User> = new Array<User>();
}
