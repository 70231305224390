import { Component } from '@angular/core';
import { LoginComponent } from '../modais/login/login.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import {changeTime} from "ngx-bootstrap/timepicker/timepicker.utils";
import { UserLogged } from '../../services/user-logged';
import { DownloadappComponent } from '../modais/downloadapp/downloadapp.component';
import { NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-como-funciona-homelife',
  templateUrl: './como-funciona-homelife.component.html',
  styleUrls: [
    './como-funciona-homelife.component.scss',
    '../../../darkmode.scss',
  ],
})
export class ComoFuncionaHomeLifeComponent {
  public bsModalRef: BsModalRef | undefined;
  public selectorTela: boolean = false;
  public isLogged: boolean = false;
  public logo: string;

  constructor(
    private modalService: BsModalService,
    private userLogged: UserLogged,
    public routes: Router,
    private meta: Meta,
    private title: Title
  ) {
    this.isLogged = userLogged.isLogged;
    this.createScriptSEO();
    this.logo = '/assets/_img/Logotipo.png';
  }

  // protected readonly changeTime = changeTime;
  public openModalLogin() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(LoginComponent, {
      backdrop: true,
    });
  }

  goToLink(comHomeLifebrasil: string) {
    window.open(comHomeLifebrasil, '_blank');
  }

  public openModalDownloadApp() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(DownloadappComponent);
  }

  createScriptSEO() {
    this.meta.addTag({
      name: 'og:title',
      content: 'HomeLife - Encontre freelancers, projetos e serviços.',
    });
    this.meta.addTag({
      name: 'title',
      content: 'HomeLife - Encontre freelancers, projetos e serviços.',
    });
    this.title.setTitle(
      'HomeLife - Encontre freelancers, projetos e serviços.'
    );
    this.meta.addTag({
      name: 'og:description',
      content: `Encontre programadores, designers, web designers, redatores, tradutores, e todos os tipos de profissionais freelancers.`,
    });
    this.meta.addTag({
      name: 'description',
      content: `Encontre programadores, designers, web designers, redatores, tradutores, e todos os tipos de profissionais freelancers.`,
    });
    this.meta.addTag({ name: 'og:url', content: window.location.href });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'og:image', content: 'favicon.ico' });
    this.meta.addTag({ name: 'canonical', content: window.location.href });
    let node = document.createElement('link');
    node.rel = 'canonical';
    node.href = window.location.href;
    document.getElementsByTagName('head')[0].appendChild(node);
    let script = document.createElement('script');
    script.type = 'application/ld+json';
    script.textContent = `
        {
          "@context": "http://schema.org",
          "@type": "offers",
          "name": "HomeLife - Encontre freelancers, projetos e serviços.",
          "description": "Encontre programadores, designers, web designers, redatores, tradutores, e todos os tipos de profissionais freelancers."
        }
    `;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  installApp() {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.homelife.twa',
      'blank_'
    );
  }
}
