import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { CategoriasComponent } from './categorias/categorias.component';
import { MoedasComponent } from './moedas/moedas.component';
import { LeiloesComponent } from './leiloes/leiloes.component';
import { UsuariosHomeLifeComponent } from './usuarios-homelife/usuarios-homelife.component';
import { PaymentsComponent } from './payments/payments.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'categoria',
        component: CategoriasComponent,
        canActivate: [AuthGuard],
      },
      { path: 'moeda', component: MoedasComponent, canActivate: [AuthGuard] },
      { path: 'leilao', component: LeiloesComponent, canActivate: [AuthGuard] },
      {
        path: 'usuarios',
        component: UsuariosHomeLifeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [BrowserModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
