import { Component } from '@angular/core';
import { LoginComponent } from '../modais/login/login.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadappComponent } from '../modais/downloadapp/downloadapp.component';
import { SenhaComponent } from '../modais/senha/senha.component';
import { Meta, Title } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserLogged } from '../../services/user-logged';
import { User } from '../../models/user/user';
import { CategoriaService } from '../../services/categoria.service';
import { Categoria } from '../../models/categoria/categoria';
import { UtilsModule } from '../../utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../../../darkmode.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class HomeComponent {
  public logo: string | undefined;
  public bsModalRef: BsModalRef | undefined;
  public searchTop: string = '';
  public searchMiddle: string = '';
  public isLogged: boolean = false;
  public user: User;
  public populares: Categoria[] = new Array<Categoria>();
  public slides: string[];
  public slideH3s: string[];
  public slideSpans: string[];
  public i: number;

  constructor(
    private modalService: BsModalService,
    public routes: Router,
    public activatedRouter: ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private userLogged: UserLogged,
    private categoriaService: CategoriaService
  ) {
    this.user = userLogged.user;
    this.isLogged = userLogged.isLogged;
    this.logo = '/assets/_img/Logotipo.png';
    // @ts-ignore
    let url = this.activatedRouter.snapshot._routerState.url;
    if (url.includes('?email=')) {
      let emailAndCode = url.split('?')[1];
      localStorage.setItem('emailAndCode', emailAndCode);
      this.modalService.show(SenhaComponent);
    }
    this.createScriptSEO();
    let searchTop = localStorage.getItem('searchFilter');
    let searchMiddle = localStorage.getItem('searchFilter');
    if (searchTop != null) this.searchTop = searchTop;
    if (searchMiddle != null) this.searchMiddle = searchMiddle;
    this.getPopulares();
    this.i = 0;
    this.slides = [
      '/assets/_img/freelancer-footer.webp',
      '/assets/_img/business-man-footer.webp',
    ];
    this.slideH3s = [
      'Prestador de serviços freelancer',
      'Sou empresa e quero contratar',
    ];
    this.slideSpans = [
      'Conecte-se com clientes em busca de soluções especializadas e destaque seu expertise.',
      'Nossa plataforma facilita a busca por talentos especializados em diversas áreas.',
    ];
  }
  public getSlideImg() {
    return this.slides[this.i];
  }
  public getSlideH3() {
    return this.slideH3s[this.i];
  }
  public getSlideSpan() {
    return this.slideSpans[this.i];
  }

  public getPrev() {
    this.i == 0 ? (this.i = this.slides.length - 1) : this.i--;
  }

  public getNext() {
    this.i < this.slides.length - 1 ? this.i++ : (this.i = 0);
  }

  public openModalLogin() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(LoginComponent);
  }

  public async getPopulares() {
    this.populares = await this.categoriaService.getPopulares();
  }

  public openModalDownloadApp() {
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(DownloadappComponent);
  }

  goToLink(comHomeLifebrasil: string) {
    window.open(comHomeLifebrasil, '_blank');
  }

  public searchAnuncios(search: string) {
    localStorage.setItem('searchFilter', search);
    localStorage.setItem('typeFilter', 'search');
    window.location.href = '/feed';
  }

  installApp() {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.homelife.twa',
      'blank_'
    );
  }

  createScriptSEO() {
    this.meta.addTag({
      name: 'og:title',
      content: 'HomeLife - Encontre freelancers, projetos e serviços.',
    });
    this.meta.addTag({
      name: 'title',
      content: 'HomeLife - Encontre freelancers, projetos e serviços.',
    });
    this.title.setTitle(
      'HomeLife - Encontre freelancers, projetos e serviços.'
    );
    this.meta.addTag({
      name: 'og:description',
      content: `Encontre programadores, designers, web designers, redatores, tradutores, e todos os tipos de profissionais freelancers.`,
    });
    this.meta.addTag({
      name: 'description',
      content: `Encontre programadores, designers, web designers, redatores, tradutores, e todos os tipos de profissionais freelancers.`,
    });
    this.meta.addTag({ name: 'og:url', content: window.location.href });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'og:image', content: 'favicon.ico' });
    this.meta.addTag({ name: 'canonical', content: window.location.href });
    let node = document.createElement('link');
    node.rel = 'canonical';
    node.href = window.location.href;
    document.getElementsByTagName('head')[0].appendChild(node);
    let script = document.createElement('script');
    script.type = 'application/ld+json';
    script.textContent = `
        {
          "@context": "http://schema.org",
          "@type": "offers",
          "name": "HomeLife - Encontre freelancers, projetos e serviços.",
          "description": "Encontre programadores, designers, web designers, redatores, tradutores, e todos os tipos de profissionais freelancers."
        }
    `;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  public filterCategoria(categoria: Categoria) {
    let name = UtilsModule.accentsTidy(
      categoria.nome.replaceAll(' ', '-')
    ).toLowerCase();
    let name2 = UtilsModule.accentsTidy(
      categoria.categoriaPaiNome.replaceAll(' ', '-')
    ).toLowerCase();
    localStorage.setItem('typeFilter', 'categoria');
    localStorage.setItem('categoriaFilter', String(categoria.id));
    localStorage.setItem('categoriaNomeFilter', categoria.nome);
    localStorage.setItem('categoriaPaiNomeFilter', categoria.categoriaPaiNome);
    window.location.href = this.routes
      .createUrlTree([name2 + '-' + name])
      .toString();
  }
}
