import { Categoria } from '../categoria/categoria';
import { User } from '../user/user';
import { AnuncioCategoria } from './anuncioCategoria';
import { Proposta } from './proposta';
import { AnuncioImagem } from './anuncioImagem';
import { AnuncioFeed } from './anuncioFeed';
import { Sistema } from '../sistem.enum';

export class AnuncioFeedModel implements AnuncioFeed {
  id!: number;
  titulo!: string;
  dttPublicacao!: Date;
  descricao!: string;
  latitude!: string;
  longitude!: string;
  endereco!: string;
  necessidade!: string;
  disponibilidade!: string;
  tipoAnuncio!: string;
  deadline!: Date;
  valor!: number;
  clienteId!: number;
  clienteName!: string;
  clientePicture!: string;
  clienteCidade!: string;
  clienteEstado!: string;
  categoriaPrincipal!: Categoria;
  tags!: AnuncioCategoria[];
  totalPropostas!: number;
  interessados!: number;
  naolidos!: number;
  mediaProposta!: number;
  imagensAnuncio!: AnuncioImagem[];
  status!: number;
  privado!: number;
  promoted!: boolean;
  dttPromoted!: Date;
  day1Promoted!: number;
  day2Promoted!: number;
  sistema: number = Sistema.HOMELIFE;
}
